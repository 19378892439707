<template>
  <v-row justify="center">
    <v-col sm="10" md="8" lg="6" align="stretch">
      <h1 class="ps-3 text-h5">Invest In Project</h1>
      <investment-form :project="project"></investment-form>
    </v-col>
  </v-row>
</template>

<script>
import InvestmentForm from './InvestmentForm.vue'

export default {
  name: 'Invest',
  components: {
    InvestmentForm,
  },
  data() {
    return {
      project: {},
    }
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.slug)
  },
}
</script>
